import React, { useEffect } from "react"
import { grey } from '@mui/material/colors'
import { API, graphqlOperation } from "aws-amplify"

import Box from '@mui/material/Box'
import { Analytics } from 'aws-amplify'
import {Amplify} from 'aws-amplify'
import {createEventsLogs} from '../graphql/mutations'


import { Typography } from "@mui/material"


import awsConfig from '../aws-exports'

Amplify.configure(awsConfig)

const UnsuscribePage = () => {

  useEffect(() => {

    const timer = setTimeout(_ => {

      if ((process.env.NODE_ENV === "production")) {

            const paramsString = window.location.search
            var searchParams = new URLSearchParams(paramsString)

            const email = searchParams.getAll("email")[0]
            const key = searchParams.getAll("key")[0]

            console.log('@PARAMS')
            console.log(paramsString)
            console.log(key)

          

            API.graphql(graphqlOperation(createEventsLogs, { input: { type: 'UNSUSCRIBE',value: email, key: key } }))
            .then(({ data }) => {
    

              console.log('@EVENT-LOG')
              console.log(data)
                
            
            })
            .catch(err => console.log(err))

           

         

      }


    }, 1000)

    return () => {
      clearTimeout(timer)
    }



  }, [])



  useEffect(() => {







  }, [])



  return (<Box width="100%" height="100%" marginTop="40vh" display="flex" flexDirection="row" justifyContent="center" justifyItems="center" alignContent="center" alignItems="center">
    < Box borderRadius="20px" width="100%" maxWidth="450px" bgcolor={grey[200]} padding="30px">
      <Typography>
        Vous nous recevrez plus desormais de newsletter de la part de Pekoia.
      </Typography>

    </Box>

  </Box>)
}





export default UnsuscribePage